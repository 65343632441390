<template>
  <main>
    尚未建置
  </main>
</template>

<route>
{
  "meta": {
    "label": "商品選項管理"
  }
}
</route>

<script>
export default {
  name: 'Dashboard',
  layout: 'manage',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
